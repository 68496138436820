import VueRouter from 'vue-router'
import Vue from 'vue'
import Router from 'vue-router'
import LandingPage from './components/LandingPage.vue'
import Homepage from './components/home.vue'
import JavaCoursePage from './views/javaCoursePage.vue'
import FrontendDeveloper from './views/FrontendDeveloper.vue'
import AIpackage from './views/AIpackage.vue'
import Devops from './views/Devops.vue'
import DataScience from './views/DatasciencePython.vue'
import DataAnalytics from './views/DataAnalyticsHadoop.vue'
import CloudPlatform from './views/CloudPlatform.vue'
import BigData from './views/BigData.vue'

Vue.use(Router)

let router  = new VueRouter({
    mode : 'history',
    routes: [
        { 
            path: '/', 
            name:'', 
            component : Homepage,
            children : [
                { path: '/', name : 'home', component : LandingPage},
                { path: '/course/java', name: 'java', component : JavaCoursePage},
                { path: '/course/frontendDeveloper', name :'frontendDeveloper', component: FrontendDeveloper},
                { path: '/course/AI', name :'ai', component: AIpackage},
                { path: '/course/devops', name :'devops', component: Devops},
                { path: '/course/dataScience', name :'dataScience', component: DataScience},
                { path: '/course/dataAnalytics', name :'dataAnalytics', component: DataAnalytics},
                { path: '/course/cloudPlatform', name :'cloudPlatform', component: CloudPlatform},
                { path: '/course/bigData', name :'bigData', component: BigData},
            ]
        },
    ]
})

export default router;
