<template>
    <div class="footer pt-4 pb-4">
        <footer class="container py-5">
            <div class="d-lg-flex d-md-flex">
                <div class="col-lg-4 col-md-4 col text-left mb-2">
                    <img src="../assets/Logo_DarkBG.svg" class="img-fluid" alt="">
                </div>
                <div class="col-lg-3 col-md-4 col text-left mt-sm-3">
                    <div class="d-flex p-lg-3 p-md-3 p-2">
                        <img src="../assets/Contactus.svg" class="img-fluid" alt="">
                        <p class="ml-3 mt-2">Contact Us</p>
                    </div>
                    <div class="d-flex p-lg-3 p-md-3 p-2">
                        <img src="../assets/TermsandconditionsCopy.svg" class="img-fluid" alt="">
                        <p class="ml-3 mt-2">Terms and Conditions</p>
                    </div>
                    <div class="d-flex p-lg-3 p-md-3 p-2">
                        <img src="../assets/PrivacyandpoliciesCopy.svg" class="img-fluid" alt="">
                        <p class="ml-2 mt-2">Privacy and Policies</p>
                    </div>
                </div>
                <div class="col-lg-5 ">
                    <div class="d-flex footersocial mt-4">
                        <div class="col-3">
                            <img src="../assets/LinkedIn.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col-3">
                            <img src="../assets/Facebook.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col-3">
                            <img src="../assets/Twitter.svg" class="img-fluid" alt="">
                        </div>
                        <div class="col-4">
                            <img src="../assets/Scroll_up_DarkBG.svg" alt="">
                            <p class="scrollup">Scroll Up</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="text-left mt-2">
                <span class="copyright ml-2 ">Careertuner@2021 All rights reserved</span>
            </div>
        </footer>
    </div>
</template>
<script>
import router from '../router'
export default {
    data() {
        return{

        }
    },
    methods : {
        NavContactUs : function() {
            router.push({
                name : 'contactUs'
            })
        },
    }
}
</script>
<style scoped>
    .copyright {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        color: rgba(255, 255, 255, .6);
    }
    p{
        margin: 0 auto;
        color: white;
    }
    .scrollup{
        font-size: 16px;
        color: rgba(255, 255, 255, .6);
    }
    @media screen and (max-width: 1020px) and (min-width: 767px) {
        .footersocial{
            position: absolute;
            bottom: 0;
        }
    }
    @media screen and (min-width: 767px) and (max-width: 1200x){
        
    }
    @media (min-width: 1200px){
        .container{
            max-width: 84%;
        }
        .footersocial{
            position: absolute;
            bottom: 0;
        }
    }
</style>