<template>
    <div>
        <Navbar class="fixed-top"/>
        <router-view class="router mt-5"/>
    </div>
</template>
<script>
import Navbar from './Navbar'
export default {
    data() {
        return{}
    },
    components : {
        Navbar
    }
}
</script>
<style scoped>
.fixed-top{
    z-index: 1000 !important;
}
</style>