import Vue from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router'
import routes from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

Vue.config.productionTip = false

// Vue.use(VueRouter)

new Vue({
  routes,
  render: h => h(App),
}).$mount('#app')
