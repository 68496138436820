<template>
  <div id="app">
    <!-- <Navbar class="fixed-top"/> -->
    <router-view class="router"/>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Navbar from './components/Navbar'
// import Footer from './components/footer'
import router from  './router'
export default {
  router,
  name: 'App',
  components: {
    // HelloWorld
    // Navbar,
    // Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}


</style>
