<template>
    <div>
        <nav id="nav" class="navbar navbar-expand-md  navbar-hover navstyle justify-content-between ">
            <div class="navbar-brand ml-lg-5 ml-md-5 ml-sm-2 p-2 pt-3 pb-3" href="#">
                <img src="../assets/Logo_LightBG.svg" class="img-fluid" @click="NavHomePage">
            </div>
            
            <div class="collapse navbar-collapse px-5 ml-5 d-none float-right" id="navbarHover">
                <ul class="navbar-nav ">
                    <li class="p-2 mr-3 nav-item dropdown">
                        <a href="#" class="link">Courses<img src="https://assets-global.website-files.com/5f6a2216919e9441da4abb1f/5f7b9e08683ff1b39c51d962_Triangle%20down.svg" class="image-3 mt-1"></a>
                        <ul class="dropdown-menu">
                            <li class="sub1 p-1 pr-2 d-flex justify-content-between" @click="NavtojavaCourse"><span>Java</span> <span class="icon">&#187;</span></li>
                            <li class="sub1 p-1 pr-2 d-flex justify-content-between" @click="NavtofrontendDev"><span>Frontend Developer(Vuejs)</span> <span class="icon">&#187;</span></li>
                            <li class="sub1 p-1 pr-2 d-flex justify-content-between" @click="NavtoAILSpackage"><span>AI ML package</span> <span class="icon">&#187;</span></li>
                            <li class="sub1 p-1 pr-2 d-flex justify-content-between" @click="NavtoDevops"><span>Devops</span> <span class="icon">&#187;</span></li>
                            <li class="sub1 p-1 pr-2 d-flex justify-content-between" @click="NavtoDatasciencePython"><span>Data science with python</span> <span class="icon">&#187;</span></li>
                            <li class="sub1 p-1 pr-2 d-flex justify-content-between" @click="NavtoDataAnalyticsHadoop"><span>Data Analytics (Hadoop)</span> <span class="icon">&#187;</span></li>
                            <li class="sub1 p-1 pr-2 d-flex justify-content-between" @click="NavtoCloudPlatform"><span>Cloud Platform</span> <span class="icon">&#187;</span></li>
                            <li class="sub1 p-1 pr-2 d-flex justify-content-between" @click="NavtoBigData"><span>Big Data</span> <span class="icon">&#187;</span></li>
                        </ul>
                    </li>
                    <li class="p-2 mr-3 " @click="NavtoContactUs"><a href="#" class="link">Contact us</a></li>
                </ul>
            </div>
            <span class=" btn requestbtn p-2 pl-4 pt-3 pr-4 mr-4 mt-2 float-right text-center contect d-none d-lg-block" @click="openStartLearning">START LEARNING</span>
            <div>
                <button class="navbar-toggler mr-3 d-md-block d-lg-none"  href="#" data-target="#demo" data-toggle="collapse" aria-haspopup="true" aria-expanded="false" @click="showMenu">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            
        </nav>

    
        <!-- For small devices menu -->
        <div id="demo" class="card  collapse p-3 " v-if="togglemenuopen">
            <ul class="list-unstyled togglemenu dropdown">
                <li @click="NavHomePage"><span>Home</span></li>
                <li data-target="#demo1" class="dropdown-toggle" data-toggle="collapse" aria-haspopup="true" aria-expanded="false"><span>Courses</span><i class="fas fa-caret-down"></i></li>
                <div id="demo1" class="collapse ml-3">
                    <li @click="NavtojavaCourse"><span>Java</span></li>
                    <li @click="NavtofrontendDev"><span>Frontend Developer(Vuejs)</span></li>
                    <li @click="NavtoAILSpackage"><span>AI ML package</span></li>
                    <li @click="NavtoDevops"><span>Devops</span></li>
                    <li @click="NavtoDatasciencePython"><span>Data science with python</span></li>
                    <li @click="NavtoDataAnalyticsHadoop"><span>Data Analytics (Hadoop)</span></li>
                    <li @click="NavtoCloudPlatform"><span>Cloud Platform</span></li>
                    <li @click="NavtoBigData"><span>Big Data</span></li>
                </div>
                <li @click="NavtoContactUs"><span>Contact Us</span></li>
            </ul>
        </div>
        <start-learning-component v-if="startLearningModal" :selectedCourseName="coureName" @closelearn="closestartLearning"></start-learning-component>
        <contact-us-component v-if="contactUsModal" @closeContact="closeContactUs"></contact-us-component>
    </div>
</template>
<script>
import router from '../router'
import startLearningComponent from './startLearning.vue'
import contactUsComponent from './contactUs.vue'
export default {
    router,
    data() {
        return {
            togglemenuopen : false,
            scrollPosition: null,
            startLearningModal : false,
            contactUsModal : false,
            coureName : 'Java full stack'
        }
    },
    components : {
        startLearningComponent,
        contactUsComponent
    },
    mounted() {
        
    },
    methods : {
        NavToCPQ : function() {
            this.showMenu();
            router.push({
                name :'cpq'
            })
        },
        NavHomePage : function() {
            this.showMenu();
            router.push({
                name : 'home'
            })
        },
        openStartLearning : function() {
            this.showMenu();
            this.startLearningModal = true;
        },
        closestartLearning : function() {
            this.startLearningModal = false;
        },
        NavtojavaCourse : function() {
            this.showMenu();
            router.push({
                name : 'java'
            })
        },
        NavtofrontendDev:function() {
            this.showMenu();
            router.push({
                name : 'frontendDeveloper'
            })
        },
        NavtoAILSpackage() {
            this.showMenu();
            router.push({
                name : 'ai'
            })
        },
        NavtoDevops() {
            this.showMenu();
            router.push({
                name : 'devops'
            })
        },
        NavtoDatasciencePython() {
            this.showMenu();
            router.push({
                name : 'dataScience'
            })
        },
        NavtoDataAnalyticsHadoop() {
            this.showMenu();
            router.push({
                name : 'dataAnalytics'
            })
        },
        NavtoCloudPlatform() {
            this.showMenu();
            router.push({
                name : 'cloudPlatform'
            })
        },
        NavtoBigData() {
            this.showMenu();
            router.push({
                name : 'bigData'
            })
        },
        closeContactUs() {
            this.contactUsModal = false
        },
        NavtoContactUs() {
            this.showMenu();
            this.contactUsModal = true
            // router.push({
            //     name : 'contactUs'
            // })
        },
        showMenu : function() {
            this.togglemenuopen = !this.togglemenuopen
        }
    },
}
</script>

<style  scoped>
.navstyle {
    background-color: white;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 6%);
}
.image-3 {
    display: block;
    width: 18px;
    height: 18px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
}
#demo{
    border-radius: 1%;
}
.requestbtn{
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 7px 20px !important;
  border-radius: 5px;
  margin: 0px 0px 0px 20px;
  background-color: #21BD8C;
  border: 3px solid #21BD8C;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  transition: 0.8s;
  color: white;
}
.requestbtn:hover{
  background-color: rgba(255,255,255,0);
  cursor: pointer;
    color: #21BD8C;
}
.togglemenu {
    padding-left: .5em;
}

.span1{
    display:flex;
    padding:.5em
}
.span2{
    display:flex;
    flex-direction:column;
    text-align: left;
}
.dropdown-item:hover {
    background-color: white;
}
.sub1 {
   padding: .5em !important;
   cursor: pointer;
   width: 225px;
   border-left: 3px solid white;
   border-radius: 5px;
   border-bottom: 1px solid rgba(0,0,0,0.2);
}
.icon {
    opacity: .4;
}
.sub1:hover .subtext{
    color: white !important;
}
.sub1:hover .icon{
    opacity: 1;
}
.sub1:hover{
    background-color: rgba(0, 197, 131, .1);
    border-left: 3px solid #00C188;
}
.sub2{
    padding-left: 1em;
}
.subtext {
    color: #c7c7c7;
    font-size: 14px;
}
a{
    color:black;
}
a:hover{
    background-color: transparent; text-decoration: none;
    color: #21BD8C;
}
img {
    width: 115%;
    height: 115%;
}
li {
    cursor: pointer;
}
.navbar {
    color: black;
}
.navbar::after{
    content: "";
    width: 100%;
    height: 4px;
    /* background: linear-gradient(-250deg, #330867 0%, #30CFD0 100%); */
    background : #edeef0;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-size: cover;
    background-repeat: no-repeat;
}
.change_color {
       background-color:white
   }
.dropdown-menu {
    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    font-size: 16px;
    margin-top: -5px;
    border: none;
}
/* .linkcontact{
    color: #0d61ff !important;
}
.link:hover {
    color: #0d61ff;
}
button {
    background-color: #0d61ff;
    color: white;
} */
.link{
    display: flex;
}
a:hover:after { 
  width: 100%; 
  left: 0; 
} 
.card {
    text-align: left;
    line-height: .9cm;
    font-size: 14px;
}
/* hover dropdown menus */

.navbar-hover .collapse ul li{position:relative;}
.navbar-hover .collapse ul li:hover> ul{
        display: flex;
    flex-direction: column;
    display:block;
    display: flex;
    color: black;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: none;
    border-radius: 15px;
    min-width:100%;
    margin-top: -5px;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-toggler {
    background: #fff;
}

@media only screen and (max-width: 770px) {
    .getStarted {
        display: none;
    }
    .navbar-nav {
        display: none;
    }
}
</style>