<template>
    <div class="mt-5">
        <section class="section AIMLPackagesBG">
            <div class="container">
                <div class="col-lg-12">
                    <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3">
                        <h1 class="heading-main11">AI/ML Package</h1>
                    </div>
                    <div class=" pr-lg-5 pl-md-3 pr-md-3 mt-4">
                        <p class="paragraph1 body-copy text-left">{{CourseDescription}}</p>
                    </div>
                    <div class="d-flex justify-content-between pr-lg-5 pl-md-3 pr-md-3 mt-5">
                        <button type = "button" class = "btn requestbtn mr-lg-5" @click="startLearning">APPLY NOW</button>
                        <div class="d-flex float-right ml-1">
                            <img src="../assets/PlayICon.svg" class="img-fluid playvideoicon" alt="">
                            <p class="watchvidtext">Watch Intro Video</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section2">
            <div class="container pr-lg-5 pl-md-3 pr-md-3">
                <div class="section2card text-left d-lg-flex  justify-content-between">
                    <div class="ml-2">
                        <p class="section2cardhead">Course duration</p>
                        <p class="section2cardsub">4 Months</p>
                    </div>
                    <div class="verticalline"></div>
                    <div>
                        <p class="section2cardhead">Course start date </p>
                        <p class="section2cardsub">{{courseStartDate}}</p>
                    </div>
                    <div class="verticalline"></div>
                    <div>
                        <p class="section2cardhead">Live project no:</p>
                        <p class="section2cardsub">4 – 40+ Industry project</p>
                    </div>
                    <div class="verticalline"></div>
                    <div>
                        <p> <span class="fee">50,000 </span> <span class="feers">Rs.</span> </p>
                    </div>
                </div>
                <div class="container-fluid ml-lg-4 pt-4 pb-4 progOverview">
                    <h2 class="revrating">About the course</h2>
                    <p class="highlightstext">{{aboutCourse}}</p>
                </div>
                <div class="container-fluid ml-lg-4 progOverview">
                    <h2 class="revrating">Program Overview</h2>
                    <p class="keyheightlights">Key Highlights</p>
                    <div class="row no-gutters">
                        <div class="col-md-6 col-lg-6" v-for="(item, index) in HighlightsPoints" :key="index">
                            <div class="highlights">
                                <img class="highlightsImg" src="../assets/Star.svg" alt="">
                                <p class="highlightstext">{{item}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section3">
            <div class="container">
                <p class="revrating text-left">Program Tools Covered</p>
                <div class="d-flex pgrmbtnsection">
                    <div v-for="(btnitem, index) in programTools" :key="index">
                        <button class="prgrmtoolsbtn" :class="{'selectedTool' :selectedTool===btnitem}">{{btnitem}}</button>
                    </div>
                </div>
            </div>
        </section>
        <section class="downloadsyllabus">
            <div class="container p-3 justify-content-center">
                <button class="selectedTool downloadsyllabusbtn d-flex p-2 pl-3 pr-3 ">
                    <img src="../assets/Downloadbtn.svg" class="mt-1" alt="">
                    <span class="section2cardhead mb-1 ml-3">Syllabus Download</span>
                </button>
            </div>
        </section>
        <section class="section4">
            <div class="container">
                <div class="card-columns d-lg-flex mb-4 d-md-flex justify-content-center">
                    <div class="cardsect1 text-left mb-3">
                        <div class="card-block1">
                            <h4 class="card-title">{{topSubObject.title}}</h4>
                            <p class="card-text">{{topSubObject.description}}</p>
                        </div>
                    </div>
                    <div class="cardsect2 text-left ml-lg-2">
                        <div class="card-block1">
                            <h4 class="card-title">{{jobOpportunityObject.title}}</h4>
                            <p class="card-text">{{jobOpportunityObject.description}}</p>
                        </div>
                    </div>
                </div>
                <div class="card-columns d-lg-flex d-md-flex justify-content-center">
                    <div class="cardsect3 text-left">
                        <div class="card-block">
                            <h4 class="card-title">{{whocanenrollObject.title}}</h4>
                            <p class="card-text">{{whocanenrollObject.description}}</p>
                        </div>
                    </div>
                    <div class="cardsect4 text-left ml-lg-2">
                        <div class="card-block">
                            <h4 class="card-title">{{eligibilityObject.title}}</h4>
                            <p class="card-text">{{eligibilityObject.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         <section class="sectioncarousel downloadsyllabus">
             <h2 class="revrating text-left ml-lg-5">Instructors</h2>
             <p class="highlightstext ml-lg-5 mb-5">Learn from Top Professors from the USA and India and the best of Industry Experts.</p>
            <div class="container text-center my-3">
                <div class="row mx-auto my-auto">
                    <div id="recipeCarousel" class="carousel slide w-100" data-ride="carousel">
                        <div class="carousel-inner w-100" role="listbox">
                            <div class="carousel-item active">
                                <div class="col-md-3">
                                    <div class="card card-body carouselslide">
                                        <img class="img-fluid rounded-circle carouselslideimg" src="http://placehold.it/380?text=1">
                                        <p class="carouselcardname">John Rand</p>
                                        <p>Microsoft</p>
                                        <img src="../assets/LinkedIn.svg" class="carouselslideimg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="col-md-3">
                                    <div class="card card-body carouselslide">
                                        <img class="img-fluid rounded-circle carouselslideimg" src="http://placehold.it/380?text=2">
                                        <p class="carouselcardname">John Rand</p>
                                        <p>Microsoft</p>
                                        <img src="../assets/LinkedIn.svg" class="carouselslideimg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="col-md-3">
                                    <div class="card card-body carouselslide">
                                        <img class="img-fluid rounded-circle carouselslideimg" src="http://placehold.it/380?text=3">
                                        <p class="carouselcardname">John Rand</p>
                                        <p>Microsoft</p>
                                        <img src="../assets/LinkedIn.svg" class="carouselslideimg"  alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="col-md-3">
                                    <div class="card card-body carouselslide">
                                        <img class="img-fluid rounded-circle carouselslideimg" src="http://placehold.it/380?text=4">
                                        <p class="carouselcardname">John Rand</p>
                                        <p>Microsoft</p>
                                        <img src="../assets/LinkedIn.svg"  class="carouselslideimg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="col-md-3">
                                    <div class="card card-body carouselslide">
                                        <img class="img-fluid rounded-circle carouselslideimg" src="http://placehold.it/380?text=5">
                                        <p class="carouselcardname">John Rand</p>
                                        <p>Microsoft</p>
                                        <img src="../assets/LinkedIn.svg" class="carouselslideimg"  alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="col-md-3">
                                    <div class="card card-body carouselslide">
                                        <img class="img-fluid rounded-circle carouselslideimg" src="http://placehold.it/380?text=6">
                                        <p class="carouselcardname">John Rand</p>
                                        <p>Microsoft</p>
                                        <img src="../assets/LinkedIn.svg" class="carouselslideimg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev w-auto" href="#recipeCarousel" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon bg-dark border border-dark rounded-circle" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next w-auto" href="#recipeCarousel" role="button" data-slide="next">
                            <span class="carousel-control-next-icon bg-dark border border-dark rounded-circle" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        </section> 
        <section class="industryprojectsection">
            <div class="container pr-lg-5 pl-md-3 pr-md-3">
                <p class="revrating text-left">Industry Projects</p>
                <p class="industrysubhead">Gain real-life experience with projects approved by top companies from around the globe</p>
                <div v-for="(industrypoint, index) in IndustryPoints" :key="index" class="d-flex">
                    <img src="../assets/Points.svg" class="img-fluid" alt="">
                    <p class="highlightstext mt-3 ml-3">{{industrypoint}}</p>
                </div>
            </div>
        </section>
        <section class="section5">
            <div class="container text-center ">
                <p class="revrating">Reviews & Ratings</p>
                <p class="revratingsub">Please share your feedback with us</p>
                <div class="d-flex p-3 mb-2 justify-content-center">
                    <img src="../assets/Start_Selected.svg" class="img-fluid" alt="">
                    <img src="../assets/Start_Selected.svg" class="img-fluid ml-1" alt="">
                    <img src="../assets/Start_Selected.svg" class="img-fluid ml-1" alt="">
                    <img src="../assets/Start_UnSelected.svg" class="img-fluid ml-1" alt="">
                    <img src="../assets/Start_UnSelected.svg" class="img-fluid ml-1" alt="">
                </div>
                <div class="section5div">
                    <textarea class="textarea" name="" id="txt" placeholder="Review Comments here" ></textarea>
                    <button class="sendCommentbtn float-right">SEND</button>
                </div>
            </div>
        </section>
        <start-learning-component v-if="startLearningModal" :selectedCourseName="coureName" @closelearn="closestartLearning"></start-learning-component>
        <Footer class="footer" />
    </div>
</template>
<script>
import Footer from '../components/footer'
import $ from 'jquery'
import startLearningComponent from '../components/startLearning.vue'
import jsonData from '../js/careertuner.json'
export default {
    data() {
        return {
            CourseDescription : 'Pave your way to a bright career in AI/ML with this highly-rated course on artificial intelligence and machine learning, curated by Career Tuner’s team of experts. Learn the basics and advanced-level concepts of AI/ML and build your career in the field of artificial intelligence.',
            aboutCourse : 'This course will help students learn the fundamental concepts of artificial intelligence and machine learning. Learn basic and advanced search algorithms to improve your skills in problem-solving, knowledge representation and reasoning, pattern recognition, fuzzy logic, and neural networks. Get access to newly updated content with exciting new projects and modules.',
            HighlightsPoints : [
                'Basic and advanced knowledge of AI/ML',
                'Hands-on projects',
                'Assignments with feedback',
                'Expert faculties and mentors ',
                'An opportunity to transform your career',
                'Full-time access',
                'Placement opportunities',
                'Certificate of completion  '
            ],
            selectedTool : 'Language Understanding',
            programTools : [
                'Language Understanding',
                'Computer vision',
                'Automatic programming ',
                'Problem formulation ',
                'Pattern recognition',
                'Neural and multilayer neural networks ',
                'Fuzzy logic ',
                'Decision trees ',
                'Population-based search ',
                'Ontology'
            ],
            topSubObject : {
                title : 'Top Subjects That You Will Learn',
                description : 'With the help of this course, you can grasp an understanding of various key subjects like Big Data, Business Intelligence, Data Science, ML, Computer Vision, NLP Engineer and many more.'
            },
            jobOpportunityObject : {
                title : 'Job Opportunity',
                description : 'The past few years are proof of the phenomenal popularity of AI/ML.  The future of technology will largely depend on artificial intelligence, and subsequently, there’s been an enormous demand for AI/ML skills.'
            },
            whocanenrollObject : {
                title : 'Who Can Enroll in This Program?',
                description : 'This program is for new learners. No degree or prior experience is required for this course. '
            },
            eligibilityObject : {
                title : 'Eligibility Description',
                description : 'No degree or prior experience is required for this course. However, a little programming language knowledge will go a long way.'
            },
            IndustryPoints : [
                'Assignments, problem-solving exercises, and hands-on projects',
                'Get feedback from experts and enhance your knowledge. '
            ],
            courseStartDate : '',
            startLearningModal : false,
            coureName : 'AI ML Package'
        }
    },
    components : {
        Footer,
        startLearningComponent
    },
    mounted() {
        window.scrollTo(0, 0)
        this.courseStartDate = jsonData.courseStartDate;
        $('#recipeCarousel').carousel({
            interval: 10000
        })
        $('.carousel .carousel-item').each(function(){
            var minPerSlide = 3;
            var next = $(this).next();
            if (!next.length) {
            next = $(this).siblings(':first');
            }
            next.children(':first-child').clone().appendTo($(this));
            
            for (var i=0;i<minPerSlide;i++) {
                next=next.next();
                if (!next.length) {
                    next = $(this).siblings(':first');
                }
                
                next.children(':first-child').clone().appendTo($(this));
            }
        });

    },
    methods : {
        startLearning() {
            this.startLearningModal = true
        },
        closestartLearning() {
            this.startLearningModal = false
        }
    }
}
</script>
<style lang="css" scoped>
    @import url('../css/common.css');
    .requestbtn{
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        padding: 7px 20px !important;
        border-radius: 5px;
        background-color: #21BD8C;
        border: 3px solid #21BD8C;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        transition: 0.8s;
        color: white;
        height: 40px;
    }
    .requestbtn:hover{
        background-color: rgba(255,255,255,0);
        cursor: pointer;
        color: #21BD8C;
    }
    @media (max-width: 767px) {
        .carousel-inner .carousel-item > div {
            display: none;
        }
        .carousel-inner .carousel-item > div:first-child {
            display: block;
        }
        .card-columns{
            display: flex;;
            flex-direction: column;
        }
    }
    @media (min-width: 767px) {
        
        .carousel-inner .carousel-item-right.active,
        .carousel-inner .carousel-item-next {
        transform: translateX(33.333%);
        }
        
        .carousel-inner .carousel-item-left.active, 
        .carousel-inner .carousel-item-prev {
        transform: translateX(-33.333%);
        }
    }
    @media (min-width: 1200px){
        .container{
            max-width: 88%;
        }
        .section4 {
            padding: 6%;
        }
    }
    @media screen and (min-width:768px) {
        .section4 {
            padding: 6%;
        }
    }
    @media only screen and (max-width: 600px) {
        .section4 {
            padding: 4% 1% ;
        }
        .section {
            padding-top: 8%;
        }
        .playvideoicon{
            width: 35px;
            height: 35px;
        }
        .watchvidtext{
            font-size: 16px;
            margin: 6px 0 0 3px;
        }
        .section5div{
            width: 100%;
        }
        .section3 {
            padding: 5% 0%;
        }
        .cardsect1 , .cardsect2, .cardsect3, .cardsect4 {
            margin: auto;
        }
    }
</style>