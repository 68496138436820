<template>
  <div class="modal-backdrop">
    <div class="card maincard col-lg-6 col-md-8 mt-md-4 col-11 p-0">
      <div class="modal-content postmodalcontent">
        <div class="modal-header p-4">          
          <h5 class="modal-title font16-bold ml-2" id="exampleModalLabel">Contact Us</h5>  
          <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body bodymodal11">
          <div class="pb-4 pl-4 pr-4 pt-0">
            <div class="row ">
               <div class="col-lg-6 col-md-6 text-left">
                    <label for="firstName" class="labelNames">Name</label>
                    <input id="firstName" type="text" placeholder="First Name" autocomplete="off" v-model="userName">
                </div>
                <div class="col-lg-6 col-md-6 text-left">
                    <label for="email" class="labelNames">Email</label>
                    <input id="email" type="email" placeholder="test@gmail.com" @keyup="emailValidation" autocomplete="off" v-model="userEmail">
                </div>
            </div>
            <div class="row mt-3">
               <div class="col-lg-6 col-md-6 text-left">
                    <label for="phoneNumber" class="labelNames">Phone Number</label>
                    <div class="d-flex">
                      <select id="countrycode" class="countrycode" v-model="countrycode" @change="changeCountryCode">
                        <option v-for="(cc,index) in countryCodes" data-thumbnail="../assets/flagUSA.png" class="option" :value="cc.countryCode" :key="index"> <img src="../assets/flagUSA.png" alt=""> {{cc.country}}({{cc.countryCode}})</option>
                      </select>
                      <input id="phoneNumber" maxlength="10" @keyup="checkMobileNumber" placeholder="9********9" autocomplete="off" v-model="phoneNumber">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 text-left">
                    <label for="preferredContact" class="labelNames">Preferred Contact</label>
                    <select id="preferredContact" v-model="preferredContactitem" >
                      <option v-for="(prefer,index) in preferredContact" class="option" :value="prefer" :key="index">{{prefer}}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 text-left">
                    <label for="description" class="labelNames">Description</label>
                    <input id="description" type="text" placeholder="Description" v-model="Description">
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="mr-3 btn button-cancel1" @click="close">
            <span class="">Cancel</span>
          </button>
          <button class="mr-3 btn sign-in-button sign-up-button" @click="ContactUsSubmit">
            <span class="button-create-font">Submit</span>
          </button>
          </div>
      </div>
    </div>
  </div>  
</template>

<script>
import httpContact from '../js/http-careertuner.js'
export default{
   data() {
    return{
        preferredContact : [
            'email',
            'phone'
        ],
        countryCodes :  [
          {country : 'USA', countryCode : '+1'},
          {country : 'India', countryCode : '+91'}
        ],
        userName : '',
        userEmail : '',
        countrycode : '+1',
        phoneNumber : '',
        preferredContactitem : 'email',
        Description :'',
        validPhonenumer : false,
        validEmail : false
    }
  },
  props : [
      'reportuser'
  ],
  methods:{
    close() {
        this.$emit('closeContact');
    },
    checkMobileNumber() {
      var regx = /^[0-9]\d{9}$/ ;
      this.validPhonenumer = regx.test(this.phoneNumber)
      if(this.validPhonenumer){
        document.getElementById('phoneNumber').classList.remove('border-red')
      }else{
        document.getElementById('phoneNumber').classList.add('border-red')
      }
    },
    emailValidation() {
      var email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      this.validEmail = email.test(this.userEmail)
      if(this.validEmail){
        document.getElementById('email').classList.remove('border-red')
        this.checkValidation()
      }else{
        document.getElementById('email').classList.add('border-red')
      }
    },
    ContactUsSubmit() {
      if(this.validPhonenumer && this.validEmail) {
        let phoneNumber = '('+ this.countrycode +')' + this.phoneNumber
        httpContact.post('/userprofile/saveData',
        {
          phoneNumber : phoneNumber,
          email : this.userEmail,
          prefferedContact : this.preferredContactitem,
          name : this.userName,
          description : this.description
        }).then((response) => {
          console.log("saved contactus : ", response)
          this.close()
        }).catch((e) => {
          console.log(e)
        })
      }else{
        if(!this.validEmail){
          alert("Please enter valid Email")
        }
        if(!this.validPhonenumer){
          alert("Please enter valid phone number")
        }
      }
    },
    changeCountryCode() {
      console.log(this.countrycode)
    }
  },
   mounted() {
  },

}

</script>

<style  scoped>
.modal-backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000 !important;
    padding-top: 3%;
}
.modal-footer{
    background-color: rgba(0, 0, 0, 0.04);
}
.maincard {
  width: 100%;
}
.card {
  margin: auto !important;
}
.labelNames{
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
}
.card-header {
  border-bottom: none;
}
.postmodalcontent{
  border: none !important;
}
.labels {
  margin-bottom: .15rem;
}
.selectedLabel {
  color: #007AFF !important;
  opacity: 1;
}
button:active {
    outline: none;
    border: none;
}
.btn:focus{
  box-shadow: none;
}
.border-red{
  border: 1px solid red;
}
.modal-header{
  border: none;
}
.bodymodal11{
    height: 300px;
    overflow: auto;
}
.countrycode{
  width: 40%;
}
.bodymodal11::-webkit-scrollbar {
  display: none;
}
select{
    width: 100%;
    padding: .6em;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 14px;
}
.coursedrop{
    border: 1px solid #21BD8C;
}
input{
    width: 100%;
    padding: .5em;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 14px;
}
input:focus{
    outline: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.bodymodal11 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.button-cancel1 {
  border: 1px solid #1f1f1f;
  opacity: 0.3;
  border-radius: 4px;
}
.close{
  padding: 1px 7px 5px 7px !important;
  /* margin-top: 0px !important; */
  margin-top: -3px !important;
  margin-right: 5px !important;
}
.reportcard1{
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0;
  border: 1px solid rgba(31,31,31,0.1);
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 10px;
  padding: 0;
}
.reportcard11 {
  display: block;
  text-align: left;
   background:
rgba(255,255,255,1);

  color: #222;
  border-radius: 10px !important;
  padding: 20px;
}
.reportcard11:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
}

.reportcard11.collapsed {
  background:
rgba(255,255,255,1);
  color: #007AFF;
}
.sign-up-button{
    background-color: #21BD8C;
    border-radius: 4px;
    color: white;
}
.sign-up-button:hover{
    color: white;
}
label:checked { 
  color: red;
 } 
.tagLineReport{
	height: 10px;
	color: #1F1F1F;
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0;
	font-style: italic;
  opacity: 60%
}
@media screen and  (max-width : 600px){
  .card{
    margin-left: 4%;
    margin-top: 4%;
  }
}
@media screen and  (min-width: 770px){
  .card{
    margin-left: 35%;
  }
}
@media screen and  (min-width: 600px) and (max-width : 768px){
  .card{
    margin-left: 15%;
  }
}
</style>