<template>
    <div class="mt-5">
        <section class="section1">
            <div class="container d-lg-flex">
                <div class="col-lg-9">
                    <div class="div-block-41 text-left pr-lg-5 pl-md-3 pr-md-3">
                        <h1 class="heading-main11"><span class="heading-main1">Interact</span> with <span class="heading-main1">best-in-class<br> educators</span>  and</h1><br>
                    </div>
                    <div class="col-lg-7 pr-lg-5 pl-md-3 pr-md-3 mt-4">
                        <p class="paragraph1 body-copy text-left">have a fantastic learning experience with live industry-driven projects. Check out our courses to begin your journey with us today!</p>
                    </div>
                    <div class="text-left pr-lg-5 pl-md-3 pr-md-3 mt-5">
                        <button type = "button" class = "btn requestbtn mr-lg-5" @click="startLearning">START LEARNING</button>
                    </div>
                    <div class="col-lg-3 col-5  pr-lg-5 pl-md-3 pr-md-3 mt-5">
                        <img src="../assets/ScrolldownCopy.svg" alt="">
                        <p class="scrolldown pt-2">Scroll down</p>
                    </div>
                </div>
                <div class="col-lg-3 mr-3">
                    <div class="row justify-content-between">
                        <img src="../assets/Landingimg1.svg" class="img-fluid" alt="">
                        <img src="../assets/Landingimg2.svg" class="img-fluid" alt="">
                    </div>
                    <div class="row justify-content-center">
                        <img src="../assets/Landingimg3.svg" class="img-fluid" alt="">
                    </div>
                    <div class="row justify-content-between">
                        <img src="../assets/Landingimg4.svg" class="img-fluid" alt="">
                        <img src="../assets/Landingimg5.svg" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </section>
        <section class="section3  mt-2 pb-4">
            <div class="container pr-lg-5 pl-md-3 pr-md-3">
                <div class=" text-center d-lg-flex justify-content-lg-between justify-content-md-around p-3  mt-lg-5 mb-lg-1">
                    <div class="section3card p-1 text-left mb-3">
                        <img src="../assets/Live_class_design_and_explanation.svg" class="img-fluid" alt="">
                        <p class="section3cardsub1">Live class design and explanation</p>
                        <p class="section3cardsub2">Enjoy live classes and explanatory sessions, chat, clear doubts, attend polls and participate in discussion forums for a wholesome classroom experience.</p>
                    </div>
                    <div class=" section3card p-1 text-left mb-3 ">
                        <img src="../assets/Interview_Preparation.svg" class="img-fluid" alt="">
                        <p class="section3cardsub1">Interview Preparation </p>
                        <p class="section3cardsub2">Are you worried about cracking technical interviews? Our experts will guide you through the roadmap of clearing difficult interviews in a few simple steps.</p>
                    </div> 
                    <div class=" section3card p-1 text-left mb-3">
                        <img src="../assets/Resume_building_Guideline.svg" class="img-fluid" alt="">
                        <p class="section3cardsub1">Resume building Guideline</p>
                        <p class="section3cardsub2">Our mentors will upskill you with the tips and tricks required to craft the perfect resume. Let your first impression on recruiters be a great one! </p>
                    </div>
                </div>
                <div class=" text-center d-lg-flex justify-content-lg-between justify-content-md-around p-3 mb-lg-3">
                    <div class="section3card p-1 mt-1 text-left mb-3">
                        <img src="../assets/Practice_and_revise.svg" class="img-fluid" alt="">
                        <p class="section3cardsub1">Practice and revise</p>
                        <p class="section3cardsub2">Our assessment services, lecture notes, and mock interviews will enable you to practice and revise all concepts thoroughly.</p>
                    </div>
                    <div class="section3card  p-1 mt-1 text-left mb-3 ">
                        <img src="../assets/Internship.svg" class="img-fluid" alt="">
                        <p class="section3cardsub1">Internship </p>
                        <p class="section3cardsub2">Grab a chance to bag internships, solve real-world industrial problems and experience corporate standards with live projects.</p>
                    </div>
                    <div class=" section3card p-1 text-left">
                    </div>
                </div>
            </div>
        </section>
        <section class="section4">
            <div class="container">
                <div class="card-columns d-lg-flex d-md-flex justify-content-center">
                    <div class="cardsect1 text-left">
                        <div class="card-block">
                            <h4 class="card-title">Top subject you will learn</h4>
                            <p class="card-text">Our courses are available across the fields of Java Full Stack, Big Data, AI/ML, DevOps, Data Science, Cloud platform, AWS, Google Cloud, Data Analytics, and many more.</p>
                        </div>
                    </div>
                    <div class="cardsect2 text-left">
                        <div class="card-block">
                            <h4 class="card-title">Job opportunity</h4>
                            <p class="card-text">The demand for skilled developers has escalated in the past few years. Ensure a prosperous career with your technical know-how in these booming domains. </p>
                        </div>
                    </div>
                </div>
                <div class="card-columns d-lg-flex d-md-flex justify-content-center">
                    <div class="cardsect3 text-left">
                        <div class="card-block">
                            <h4 class="card-title">Who Can Enroll in Our Programs</h4>
                            <p class="card-text">All enthusiastic college graduates, job seekers, cross-platform switchers, and experienced professionals can enroll in our programs.</p>
                        </div>
                    </div>
                    <div class="cardsect4 text-left">
                        <div class="card-block">
                            <h4 class="card-title">Eligibility Description</h4>
                            <p class="card-text">Anyone with basic technical knowledge and the curiosity to learn new skills are eligible to join our courses.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section5">
            <div class="container text-center ">
                <p class="revrating">Reviews & Ratings</p>
                <p class="revratingsub">Please share your feedback with us</p>
                <div class="d-flex p-3 mb-2 justify-content-center">
                    <img src="../assets/Start_Selected.svg" class="img-fluid" alt="">
                    <img src="../assets/Start_Selected.svg" class="img-fluid ml-1" alt="">
                    <img src="../assets/Start_Selected.svg" class="img-fluid ml-1" alt="">
                    <img src="../assets/Start_UnSelected.svg" class="img-fluid ml-1" alt="">
                    <img src="../assets/Start_UnSelected.svg" class="img-fluid ml-1" alt="">
                </div>
                <div class="section5div">
                    <textarea class="textarea" name="" id="txt" placeholder="Review Comments here" ></textarea>
                    <button class="sendCommentbtn float-right">SEND</button>
                </div>
            </div>
        </section>
        <start-learning-component v-if="startLearningModal" :selectedCourseName="coureName" @closelearn="closestartLearning"></start-learning-component>
        <Footer class="footer" />
    </div>
</template>
<script>
import Footer from './footer'
import startLearningComponent from './startLearning.vue'
export default {
    data() {
        return {
            startLearningModal : false,
            coureName : 'Java full stack'
        }
    },
    components : {
        Footer,
        startLearningComponent
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    methods : {
        startLearning() {
            this.startLearningModal = true
        },
        closestartLearning() {
            this.startLearningModal = false
        }
    }
}
</script>
<style lang="css" scoped>
    .section{
        padding-top: 8%;
        padding-bottom: 10%;
        color:white;
        background-size: cover;
        -webkit-background-size: cover;
        overflow: hidden;
    }
    p{
        color: #17181a;
    }
    /* section4 */
    .section4{
        border-bottom: 4px solid #FFC37B;
    }
    .cardsect1{
        background-image: url('../assets/Topsubjectyouwilllearn_BG.svg');
        background-repeat: no-repeat;
    }
    .cardsect2{
        background-image: url('../assets/Jobopportunity_BG.svg');
        background-repeat: no-repeat;
    }
    .cardsect3{
        background-image: url('../assets/WhoisthisProgramFor__BG.svg');
        background-repeat: no-repeat;
        margin-bottom: 20px;
    }
    .cardsect4{
        background-image: url('../assets/EligibilityDescription_BG.svg');
        background-repeat: no-repeat;
    }
    .card-block{
        width: 320px !important;
        height: 350px !important;
    }
    .card-title{
        color: #F77979;
        padding: 32px 31px 0 40px;
        font-size: 38px;
        font-family: Arial, Helvetica, sans-serif;
    }
    .card-text{
        color: rgba(31,31,31,0.8);
        font-size: 16px;
        line-height: 24px;
        padding: 10px 24px 0 40px;
    }
    /* end section4 */

    /* section5 */
    .section5{
        background-color: #FFF7EE;
        padding: 4%;
    }
    .revrating{
        font-size: 32px;
        font-weight: bold;
        color: black;
        margin-bottom: .7rem;
    }
    .revratingsub{
        font-size: 32px;
        font-weight: lighter;
    }
    .section5div{
        display:inline-block;
        position:relative;
        width: 70%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.2);
    }
    .textarea{
        background-color: #FFF7EE;
        padding: 20px;
        border: none;
        border-radius: 10px;
        width: 100%;
        overflow: hidden;
    }
    .textarea:focus{
        outline: none;
    }
    .sendCommentbtn{
        /* position:absolute; */
        /* bottom:10px;
        right:0; */
        background-color: #FFC37B;
        border-radius: 4px;
        border: none;
        height: 40px;
        width: 91px;
        margin-bottom: 10px;
        margin-right: 10px;
        font-size: 16px;
        font-weight: bold;
    }
    /* end of section5 */
    .heading-main1{
        font-weight: 1000;
    }
    .heading-main11{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 56px;
        color: #1F1F1F;
    }
    @keyframes flow {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    .paragraph.body-copy {
        display: inline-block;
        margin-bottom: 24px;
        /* color: #686a6e; */
        text-align: left;
        white-space: break-spaces;
    }
    .paragraph1 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 24px;
        color: rgba(31, 31, 31, .8);
    }
    .requestbtn{
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        padding: 7px 20px !important;
        border-radius: 5px;
        background-color: #21BD8C;
        border: 3px solid #21BD8C;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        transition: 0.8s;
        color: white;
    }
    .requestbtn:hover{
        background-color: rgba(255,255,255,0);
        cursor: pointer;
        color: #21BD8C;
    }
    .footer {
        background-color: black;
        color : white
    }
    .scrolldown {
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        color: #21BD8C;
    }
    h4{
        font-weight: 700;
    }
    .card {
        border: none;
        border-radius: 5%;
    }
    .section3cardsub1{
        font-size: 32px;
        font-weight: 900;
        color: rgba(31, 31, 31, 0.8);
        text-align: left;
        margin-left: 3px;
        width: 80%;
    }
    .section3cardsub2{
        font-size: 16px;
        color: rgba(31, 31, 31, 0.8);
        text-align: left;
        margin-left: 3px;
        width: 80%;
    }
    .section3 {
        background-color: #FFF7EE;
        border-bottom: 4px solid #FFC37B;
    }
    @media (min-width: 1200px){
        .container{
            max-width: 88%;
        }
        .section3card{
            margin-top: -18%;
        }
        .section4 {
            padding: 6%;
        }
    }
    @media screen and (min-width:768px) {
        .section3card {
            /* border: none; */
            width: calc(31.33333% - 1.11111rem) !important;
        }
        .section4 {
            padding: 6%;
        }
    }
    @media screen and (min-width:500px) and (max-width:768px){
        .section3card {
            /* border: none; */
            width: calc(99.99999% - 1.11111rem) !important;
        }
    }
    @media only screen and (max-width: 600px) {
        .section4 {
            padding: 4% 1% ;
        }
        .section {
            padding-top: 8%;
        }
        .section5div{
            width: 100%;
        }
    }
</style>